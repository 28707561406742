import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import CategoryTemplateDetails from "../components/CategoryTemplateDetails";

class CategoryTemplate extends React.Component {
  render() {
    const { title, subtitle, author } = this.props.data.site.siteMetadata;
    const { category } = this.props.pageContext;
    const description = subtitle;
    const url = typeof window !== "undefined" ? window.location.href : "";
    const origin = typeof window !== "undefined" ? window.location.origin : "";
    const profilePic = require("../pages/logo.png");
    const headTitle = `All Posts categorised as "${category}" - ${title}`;
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{headTitle}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            <meta property="og:url" content={`${url ? url : origin}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={headTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${origin}${profilePic}`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={author.twitter} />
            <meta name="twitter:title" content={headTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="monetization" content="$ilp.uphold.com/QzG6Ww2rKUzq" />

          </Helmet>
          <Sidebar {...this.props} />
          <CategoryTemplateDetails {...this.props} />
        </div>
      </Layout>
    );
  }
}

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        feature {
          label
          path
        }
        deployed {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          category: { eq: $category }
          layout: { eq: "post" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;
