import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import "./style.scss";
import { trackEvent } from "../../util/google-analytics";
import { Sparkles } from "../Sparkle";
import { getUpdatedDate } from "../../util/helper";
class Post extends React.Component {
  render() {
    const {
      title,
      date,
      category,
      description,
      showYear
    } = this.props.data.node.frontmatter;

    const postTitle = showYear == false ? title : `${title} in ${new Date().getFullYear()}`;
    const { slug, categorySlug, readingTime } = this.props.data.node.fields;
    return (
      <div className="post">
        <div className="post__meta">
          <time
            className="post__meta-time"
            dateTime={moment(date).format("MMMM D, YYYY")}
          >
            {getUpdatedDate(date)}
          </time>
          <span className="post__meta-readingTime">
            {" "}
            • {readingTime.text} • {""}
          </span>
          <span className="post__meta-category" key={categorySlug}>
            <Link
              to={categorySlug}
              className="post__meta-category-link"
              onClick={(e) =>
                trackEvent(e, "Home Page Posts", "Click", "Tag-" + title)
              }
            >
              {category}
            </Link>
          </span>
        </div>
        <h2 className="post__title">
          <Link
            className="post__title-link"
            to={slug}
            onClick={(e) =>
              trackEvent(e, "Home Page Posts", "Click", "Title-" + title)
            }
          >
            {postTitle}
          </Link>
        </h2>
        <p className="post__description">{description}</p>
        <Link
          className="post__readmore"
          to={slug}
          onClick={(e) =>
            trackEvent(e, "Home Page Posts", "Click", "Read-" + title)
          }
        >
          <Sparkles>Read More →</Sparkles>
        </Link>
      </div>
    );
  }
}

export default Post;
